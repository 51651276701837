body,
html {
  height: 100%;
  margin: 0;
}
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body,
html {
  height: 100%;
  margin: 0;
}
.body {
  padding-top: 70px;
}

.home {
  background-image: url(/static/media/background.e31e6051.jpeg);
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home-heading {
  position: relative;
  top: 16vh;
  /* left: 10vw; */
  letter-spacing: 2px;

  font-size: min(8vw, 70px);
  font-weight: 200;
  color: rgb(255, 224, 199);
  text-align: center;
}
.home-attributes-text {
  /* display: inline; */
  position: relative;

  top: 20vh;
  /* left: 17vw; */
  font-size: min(8vw, 70px);
  font-weight: 200;
  color: rgb(255, 224, 199);
  text-align: center;
}

.home-attributes {
  /* display: inline; */
  position: relative;
  text-align: center;
  font-size: min(8vw, 70px);
  font-weight: 300;
  color: rgb(255, 224, 199);
}
.homeLinks {
  position: relative;
  top: 22vh;
  letter-spacing: 2px;
  padding: 8px 15px;
  font-size: min(8vw, 70px);
  font-weight: 200;
  color: rgb(192, 156, 132);
  text-align: center;
}
.homeLinks a:link {
  color: rgb(192, 156, 132);
  font-size: min(4vw, 40px);
  padding: 8px 15px;
  margin-left: 18px;
  text-decoration: none;
  display: inline-block;
  font-style: lighter;
  /* font-weight: 3px; */
}
.homeLinks a:visited {
  font-size: min(4vw, 40px);
  color: rgb(236, 187, 155);
  text-decoration: none;
  font-style: lighter;
  /* font-weight: 3px; */
}

.homeLinks a:hover {
  font-size: min(4vw, 40px);

  color: rgb(75, 63, 55);

  text-decoration: none;
}

.react-rotating-text-cursor {
  -webkit-animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s
    infinite;
          animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s
    infinite;
}

@-webkit-keyframes blinking-cursor {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes blinking-cursor {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* NAVBAR */

.navTitle {
  color: dimgrey;
  font-style: lighter;
  font-weight: lighter;
}

.navTitle a:link {
  color: dimgrey;
  font-size: 28px;
  font-style: lighter;
  font-weight: lighter;
  text-decoration: none;
}

.navTitle a:visited {
  color: dimgrey;
  font-size: 28px;
  font-style: lighter;
  font-weight: lighter;
  text-decoration: none;
}
.navTitle a:hover {
  color: rgb(85, 84, 84);
  font-size: 28px;
  font-style: lighter;
  font-weight: normal;
  text-decoration: none;
}
.navLink {
  display: inline; /*display list horizontally*/
}
.navLink.dropdown.nav-item {
  color: dimgrey;
  text-decoration: none;
  font-style: lighter;
  font-weight: lighter;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.dropdown-menu.show {
  display: block;
  text-align: center;
  border-spacing: 0ch;
}

.navLink a:link {
  color: dimgrey;
  padding: 10px 35px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-style: lighter;
  font-weight: lighter;
}
.navLink a:visited {
  color: dimgrey;
  padding: 10px 35px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-style: lighter;
  font-weight: lighter;
}

.navLink a:hover {
  color: rgb(85, 84, 84);
  padding: 10px 33px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

/* project cards */

.projectBadge.badge {
  padding: 2% 2%;
  position: center;
  font-style: lighter;
  font-weight: lighter;
  font-size: 18px;
  background-color: rgb(219, 215, 255);
  color: black;
}

.badges {
  height: 95px;
  padding: 15px 0px;
  display: center;
  font-size: 17px;
  font-weight: normal;
  text-align: center;
}

.badges > span {
  display: inline-block;
  display: -moz-inline-box;
  padding: 6% 6%;
  *display: inline;
  margin-left: 7px;
  margin-top: 7px;
  position: center;
  text-align: center;
  font-size: 17px;
  font-weight: normal;
}

.cardBox {
  display: flex;
  padding: 5px 5px;
  position: relative;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  display: "flex";
  flex-direction: "row";
  align-items: center;
  justify-content: center;
  text-align: center;
}

.portfolioCard {
  flex: 1 1;
  position: center;
  padding: 5px 5px;
  min-width: 275px;
}
.portfolioCard.card-deck {
  padding: 25px 25px;
  margin-left: 7px;
  margin-right: 7px;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  display: "flex";
  align-items: center;
  justify-content: center;
  flex-direction: "row";
}
.portfolioCard.card-title {
  /* padding: 10px 0px; */

  color: rgb(175, 197, 236);
  font-weight: normal;
  font-size: 34px;
  padding: 0px;
  height: 82px;
  margin-top: 0;
  margin-bottom: 3px;
  text-align: center;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  width: 100%;
}
.portfolioCard.card {
  min-width: 300px;
  max-width: 400px;
  margin: 30px 10px 30px 10px;
  padding: 10px 15px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.3);
}
.portfolioCard.card-body {
  padding: 0px 20px;
  min-width: 275px;
}

.portfolioCard.card-subtitle {
  padding-top: 4px;
  margin-bottom: 3px;
  font-size: 22px;
  font-weight: normal;
  color: #6c757d;
}

.card-text {
  text-align: left;
  height: 240px;
  font-weight: lighter;
  font-size: large;
}
.links {
  text-align: center;
  display: block;
  height: 50px;
}
.cardLink.card-link {
  color: #6c757d;
  font-style: lighter;
  font-weight: lighter;
}
.cardLink.card-link {
  color: #6c757d;
  font-style: lighter;
  font-weight: lighter;
}

.cardLink.card-link:link {
  color: #6c757d;
  font-size: 19px;
  font-style: lighter;
  font-weight: lighter;
  text-decoration: none;
}

.cardLink.card-link:visited {
  color: #6c757d;
  font-size: 19px;
  font-style: lighter;
  font-weight: lighter;
  text-decoration: none;
}
.cardLink.card-link:hover {
  color: rgb(41, 41, 41);
  font-size: 19px;
  font-style: lighter;
  font-weight: normal;
  text-decoration: none;
}
/* background iamge */
.backImage {
  position: relative;
  display: flex;
  scroll-padding-top: 10;
  width: 100%;
  size: 100%;
}
.backText {
  position: absolute;
  padding: 10px;
}

/* ABOUT */
/* PROFILE */

.profile.card-deck {
  top: 15%;
  position: absolute;
  width: 78%;
  left: 15%;
}

.profile.card {
  border: none;
  min-width: 380px;
  max-width: 600px;
  display: table-cell;
  vertical-align: middle;
  padding-bottom: 30px;
}

.profile.card-body {
  padding: 0px 5px;
  color: black;
  font-weight: lighter;
  font-size: 22px;
  text-align: center;
}

.profileImage.card {
  padding: 0px 0px;
  min-width: 380px;
  max-width: 600px;
  height: 80vh;
  position: center;
}

.card-img {
  object-fit: cover;
  position: center;
  width: inherit;
  height: inherit;
}

/* RESUME */

.resume {
  background-image: url(/static/media/resume.fc5a0da8.jpg);
  height: 90vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 60px;
}

/* TEACHING */
.class.card {
  margin-top: 80px;
  padding: 10px 43px;
  border: none;
  margin-left: 28px;
  margin-right: 28px;
}

.class.card-title {
  font-size: 30px;
  font-weight: 300;
  color: rgb(131, 168, 236);
  margin-bottom: 3px;
}

.class.card-subtitle {
  color: #6c757d;
  font-size: 26px;
  font-weight: 300;
}

.class.card-body {
  color: black;
  font-size: 20px;
  font-weight: lighter;
  padding: 0px 0px;
  margin-top: 8px;
  margin-bottom: 6px;
}

.class.card-link {
  font-size: 20px;
  margin-top: 0px;
  color: #6c757d;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.classes a:link {
  text-decoration: none;
}
.classes a:visited {
  text-decoration: none;
}
.classes a:hover {
  text-decoration: none;
  font-weight: bold;
}

.classes.card-deck {
  padding: 225px 25px;
  margin-left: 57px;
  margin-right: 57px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  display: "flex";
  align-items: center;
  justify-content: center;
  flex-direction: "row";
}
.classes.card-deck .card {
  margin-bottom: 30px;
  margin-left: 40px;
  margin-right: 40px;
}

.classes.card {
  min-width: 380px;
  max-width: 500px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.12),
    0 1px 4px -1px rgba(0, 0, 0, 0.3);
}

.classes.card-title {
  color: rgb(175, 197, 236);
  font-weight: 10px;
  font-style: lighter;
  text-align: center;
  padding: 10px 20px;
  margin-top: 10px;
  margin-right: 20px;
  margin-left: 20px;
  font-size: 30px;
}
.classes.card-subtitle {
  color: rgb(175, 197, 236);
  font-size: 26px;
  font-weight: lighter;
  text-align: center;
  padding: 20px 20px;
  /* margin-top: 10px; */
  margin-right: 20px;
  margin-left: 20px;
}

.classes.card-body {
  color: black;
  font-weight: 100;
  padding: 20px 20px;
  text-align: center;
}

/* SECTION INFO */
.teaching {
  padding: 15px 20px;
  margin-left: 40px;
  margin-right: 40px;
}

.section.card {
  padding: 10px 10px;
  margin-bottom: 20px;
  min-width: 300px;
}

.section.card-title {
  color: rgb(131, 168, 236);
  padding: 0px 7px;
  margin-top: 4px;
  margin-bottom: 0px;
  font-size: 30px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: inline-blocks;
  position: relative;
}

.section.card-subtitle {
  top: 12px;
  right: 12px;
  position: absolute;
  text-align: right;
  color: #6c757d;
  font-size: 23px;
  font-weight: lighter;
  width: 120px;
  padding-bottom: 5px;
}

.section.card-body {
  position: relative;
  padding: 7px 7px;
  margin-top: 10px;
  font-style: lighter;
  font-weight: lighter;
  margin-bottom: 0px;
  font-size: 17px;
  font-weight: lighter;
  color: black;
}

